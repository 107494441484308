<template>
  <svg
    id="Icon_ionic-logo-whatsapp"
    data-name="Icon ionic-logo-whatsapp"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 17.5 17.5"
  >
    <path
      id="Icon_ionic-logo-whatsapp-2"
      data-name="Icon ionic-logo-whatsapp"
      d="M10.159,1.25A8.558,8.558,0,0,0,1.568,9.775a8.437,8.437,0,0,0,1.233,4.4L1.25,18.75l4.756-1.511A8.606,8.606,0,0,0,18.75,9.775,8.558,8.558,0,0,0,10.159,1.25Zm4.272,11.762a2.219,2.219,0,0,1-1.519.979c-.4.021-.414.312-2.611-.642A8.964,8.964,0,0,1,6.678,9.925a4.213,4.213,0,0,1-.811-2.283A2.434,2.434,0,0,1,6.7,5.855.841.841,0,0,1,7.3,5.6c.173,0,.285-.005.413,0s.32-.027.486.416.564,1.529.615,1.64a.4.4,0,0,1,0,.382,1.492,1.492,0,0,1-.233.355c-.115.123-.241.275-.343.369s-.233.218-.113.44a6.574,6.574,0,0,0,1.163,1.552A5.994,5.994,0,0,0,11.014,11.9c.215.117.344.1.478-.036s.572-.615.726-.827.3-.171.5-.091,1.256.647,1.472.764.359.177.411.27A1.8,1.8,0,0,1,14.431,13.012Z"
      transform="translate(-1.25 -1.25)"
      fill="#179a0c"
      :style="iconStyle"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.5',
    },
    width: {
      type: String,
      default: '17.5',
    },
    color: {
      type: String,
      default: 'text-color',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
